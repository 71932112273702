'use client'

import { DevToolsIcon } from '@/components/foundations/DevToolsIcon'
import { useModalState } from '@/utils/hooks/useModalState'

export const DevTools = (props: { buildVersion?: string }) => {
	return (
		<div className="fixed z-50 bottom-[12px] right-[72px]">
			<button className="flex flex-row items-center justify-center gap-2 !rounded-full w-[45px] h-[45px] bg-dark" onClick={() => useModalState.getState().openDevToolsModal({ buildVersion: props.buildVersion })}>
				<DevToolsIcon />
			</button>
		</div>
	)
}
