'use client'

import { getQueryClient, persister } from '@/utils/lib/get-query-client'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { persistQueryClientRestore, persistQueryClientSave } from '@tanstack/react-query-persist-client'
import { Session } from 'next-auth'
import React, { useState } from 'react'
import { useIsClient } from 'usehooks-ts'

const ReactQueryProvider = ({ children, session, showDevTools }: { children: React.ReactNode; session: Session | null; showDevTools: boolean }) => {
	const [client] = useState(getQueryClient())
	const isClient = useIsClient()

	if (!isClient || !persister || !client) return null

	if (session?.user) {
		//if the user is logged the local storage should be restored
		//if there's no header menu in the local storage, the header menu will be fetched client side the first time
		persistQueryClientRestore({
			queryClient: client,
			persister,
		})
	} else {
		//if the user is not logged the local storage should be cleared
		persistQueryClientSave({
			queryClient: client,
			persister,
			dehydrateOptions: {
				shouldDehydrateQuery: () => false,
			},
		})
	}

	return (
		<QueryClientProvider client={client}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
			{showDevTools && (
				<React.Suspense fallback={null}>
					<ReactQueryDevtoolsProduction />
				</React.Suspense>
			)}
		</QueryClientProvider>
	)
}

// eslint-disable-next-line prettier/prettier
// prettier-ignore
const ReactQueryDevtoolsProduction = React.lazy(() =>
	import('@tanstack/react-query-devtools/build/modern/production.js').then((d) =>
		({ default: d.ReactQueryDevtools })))

export default ReactQueryProvider
